import apiUtils from '../apiUtils';

const service = {};
const serviceName = 'ParametrosPortal';

service.get = () => {
  return apiUtils.exec(serviceName);
};

service.post = request => {
  return apiUtils.exec(serviceName, apiUtils.getFetchParameters('POST', request));
};

service.put = request => {
  return apiUtils.exec(serviceName, apiUtils.getFetchParameters('PUT', request));
};

export default service;